// src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import supabase from '../config/supabaseClient'; // Assurez-vous que le chemin est correct

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fonction pour initialiser l'authentification
    const initializeAuth = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        console.log('Session initiale :', session);
        setSession(session);
      } catch (error) {
        console.error('Erreur lors de la récupération de la session:', error.message);
      } finally {
        setLoading(false);
      }
    };

    initializeAuth();

    // Écoute des changements d'état d'authentification
    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      console.log('Changement de session :', session);
      setSession(session);
      setLoading(false);
    });

    // Nettoyage de l'abonnement
    return () => subscription?.unsubscribe();
  }, []);

  // Méthode de connexion
  const signIn = async (provider) => {
    try {
      const { error } = await supabase.auth.signInWithOAuth({ provider });
      if (error) throw error;
    } catch (error) {
      console.error('Erreur d\'authentification:', error.message);
      // Optionnel : Ajouter une gestion d'erreur utilisateur ici
    }
  };

  // Méthode de déconnexion
  const signOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error.message);
      // Optionnel : Ajouter une gestion d'erreur utilisateur ici
    }
  };

  return (
    <AuthContext.Provider value={{ session, signIn, signOut, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook personnalisé pour utiliser le contexte d'authentification
export const useAuth = () => {
  return useContext(AuthContext);
};
