// src/components/Auth.js
import React from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const Auth = () => {
  const { signIn } = useAuth();
  const navigate = useNavigate();

  const signInWithGoogle = async () => {
    await signIn('google');
    navigate('/'); // Rediriger après une connexion réussie
  };

  return (
    <div>
      <h1>Connexion</h1>
      <button onClick={signInWithGoogle}>Se connecter avec Google</button>
    </div>
  );
};

export default Auth;
