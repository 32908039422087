import axios from "axios";

// Définir l'URL de base du backend Express
const axiosClient = axios.create({
  baseURL: "http://localhost:5000/api", // Assure-toi que l'URL est correcte
  headers: {
    "Content-Type": "application/json",
  },
});

export default axiosClient;
