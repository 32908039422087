import React, { useState, useEffect } from "react";
import axiosClient from "../config/axiosClient";
import "./Manaventura.css";

function HotelFilter() {
  const today = new Date().toISOString().split("T")[0];
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [hotels, setHotels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    title: "",
    description: "",
    availability: "",
  });
  const [dashboardData, setDashboardData] = useState({
    total: 0,
    available: 0,
    percentageAvailable: "0",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosClient.get("/hotels");
        const hotelData = response.data || [];
        console.log("🔍 Hôtels reçus :", hotelData);
        setHotels(hotelData);
        setLoading(false);

        const total = hotelData.length;
        const available = hotelData.filter((hotel) =>
          hotel.details?.some((d) => d.toLowerCase().includes("disponible"))
        ).length;
        const percentageAvailable = total > 0 ? ((available / total) * 100).toFixed(2) : "0";

        setDashboardData({
          total,
          available,
          percentageAvailable,
        });
      } catch (error) {
        console.error("❌ Erreur lors de la récupération des données :", error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleFilterChange = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  const filteredHotels = hotels.filter((hotel) => {
    const titleMatch = hotel.title?.toLowerCase().includes(filters.title.toLowerCase()) ?? true;
    const descriptionMatch = hotel.description?.toLowerCase().includes(filters.description.toLowerCase()) ?? true;
    const availabilityMatch =
      filters.availability === "" ||
      (filters.availability === "Disponible" &&
        hotel.details?.some((d) => d.toLowerCase().includes("disponible"))) ||
      (filters.availability === "Non disponible" &&
        !hotel.details?.some((d) => d.toLowerCase().includes("disponible")));

    return titleMatch && descriptionMatch && availabilityMatch;
  });

  return (
    <div className="app-container">
      <aside className="sidebar">
        <h1 className="sidebar-title">Filtres</h1>
        <form className="sidebar-form">
          <div className="form-group">
            <label>Date de début :</label>
            <input type="date" value={startDate} min={today} onChange={(e) => setStartDate(e.target.value)} className="input-field" />
          </div>
          <div className="form-group">
            <label>Date de fin :</label>
            <input type="date" value={endDate} min={startDate} onChange={(e) => setEndDate(e.target.value)} className="input-field" />
          </div>
          <div className="form-group">
            <label>Nombre d'adultes :</label>
            <input type="number" min="1" value={adults} onChange={(e) => setAdults(e.target.value)} className="input-field" />
          </div>
          <div className="form-group">
            <label>Nombre d'enfants :</label>
            <input type="number" min="0" value={children} onChange={(e) => setChildren(e.target.value)} className="input-field" />
          </div>
        </form>

        <div className="dashboard-section">
          <h2>Tableau de Bord</h2>
          <p>Total des hébergements : {dashboardData.total}</p>
          <p>Hébergements disponibles : {dashboardData.available}</p>
          <p>Pourcentage disponible : {dashboardData.percentageAvailable}%</p>
        </div>
      </aside>

      <main className="main-content">
        <h1 className="main-title">Manaventura Hébergement</h1>
        <div className="content-grid">
          <div className="table-section">
            <h2>Tableau des Hébergements</h2>
            {loading ? (
              <p>Chargement des données...</p>
            ) : filteredHotels.length === 0 ? (
              <p>Aucun hôtel disponible.</p>
            ) : (
              <table className="hotel-table">
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Titre</th>
                    <th>Description</th>
                    <th>Détails</th>
                    <th>Disponibilité</th>
                    <th>Lien</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredHotels.map((hotel) => (
                    <tr key={hotel._id?.toString() || Math.random().toString()}>
                      <td>{hotel.images?.[0] ? <img src={hotel.images[0]} alt={hotel.title} className="thumbnail" /> : "Pas d'image"}</td>
                      <td>{hotel.title || "Non spécifié"}</td>
                      <td>{hotel.description || "Non spécifié"}</td>
                      <td>{hotel.details?.join(", ") || "Non spécifié"}</td>
                      <td>{hotel.details?.some((d) => d.toLowerCase().includes("disponible")) ? "Disponible" : "Non disponible"}</td>
                      <td><a href={hotel.url} target="_blank">Voir plus</a></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}

export default HotelFilter;
