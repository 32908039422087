// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import Home from './pages/Home';
import LegalPrivacy from './pages/LegalPrivacy';
import Flux from './pages/Flux';
import Contact from './pages/Contact';
import HotelFilter from './pages/Manaventura';
import MesFlux from './pages/MesFlux';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import TermsofService from './pages/TermofService';
import Profile from './pages/Profile';
import ProtectedRoute from './components/ProtectedRoute';
import Auth from './components/Auth';
import FlightForm from './components/FlightForm';
import axiosClient from './config/axiosClient';

const AppRoutes = () => {
  const { session } = useAuth();

  useEffect(() => {
    // Ajouter l'intercepteur lorsque le session change
    const requestInterceptor = axiosClient.interceptors.request.use(
      (config) => {
        if (session) {
          config.headers.Authorization = `Bearer ${session.access_token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    return () => {
      axiosClient.interceptors.request.eject(requestInterceptor);
    };
  }, [session]);

  return (
    <Routes>
      <Route path="/auth" element={<Auth />} />
      <Route path="/" element={<Home />} />
      <Route
        path="/flux"
        element={
          <ProtectedRoute>
            <Flux />
          </ProtectedRoute>
        }
      />
      <Route path="/legalprivacy" element={<LegalPrivacy />} />
      <Route path="/termofservice" element={<TermsofService />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/manaventura" element={<HotelFilter />} />
      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/flight"
        element={
          <ProtectedRoute>
            <FlightForm />
          </ProtectedRoute>
        }
      />
      <Route
        path="/mesflux"
        element={
          <ProtectedRoute>
            <MesFlux />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<Home />} />
    </Routes>
  );
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <Navbar />
        <AppRoutes />
        <Footer />
      </Router>
    </AuthProvider>
  );
}

export default App;
